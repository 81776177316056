<template>
  <section>
    <form role="form" style="height: 100%;">
      <div class="checkbox-inline all">
        <label
          for="all"
          :title="
            group && group.is_system
              ? $t('titles.group_is_system')
              : $tc('titles.select_all')
          "
        >
          <input
            type="checkbox"
            data-testid="all-permissions"
            id="all"
            v-model="all"
            v-bind:disabled="isSystemGroup"
          />
          <span>
            {{ $t("permissions") }}
            <Tooltip
              v-if="!(group || {}).is_system"
              :title="$t('hints.group_permissions')"
            />
          </span>
        </label>
      </div>
      <div class="items-content">
        <div class="" v-for="(item, ix) in items" v-bind:key="ix">
          <label class="checkbox-inline permission">
            <input
              type="checkbox"
              v-on:click="check(item)"
              :data-testid="'permission-' + item.id"
              :title="
                group && group.is_system
                  ? $t('titles.group_is_system')
                  : $t('titles.select')
              "
              v-model="item.checked"
              v-bind:disabled="isSystemGroup || isBlocked(item)"
            />
            <span>
              {{ item.description }}
              <Tooltip
                class="permission-hint"
                :title="$t(`group_permissions.${item.name}`)"
              />
            </span>
          </label>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "UserGroupPermissions",
  components: { Tooltip },
  props: {
    group: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      all: false,
      entry: null,
      items: []
    };
  },
  computed: {
    isSystemGroup() {
      return (this.group && this.group.is_system) || false;
    },
    groupName() {
      return (
        (this.group && this.group.name) ||
        this.$tc("group") + " " + this.$t("not_selected")
      );
    },
    portalPermissions() {
      return (this.items || []).filter((i) => i.checked).map((i) => i.id);
    }
  },
  watch: {
    all(n) {
      if (!this.isSystemGroup) {
        this.checkAll(n);
      }
    },
    portalPermissions: {
      handler(n) {
        this.$emit("input", n);
      }
    },
    group: {
      handler(n) {
        if (n) {
          var entry = JSON.parse(JSON.stringify(n));
          entry.portal_permissions = entry.portal_permissions || [];
          this.$set(this, "entry", entry);
        } else {
          this.$set(this, "entry", null);
        }
        this.setItems();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initData() {
      this.$set(this, "entry", this.group);
      this.setItems();
    },
    checkAll(option) {
      let self = this;
      let lst = (self.items || []).map(function(i) {
        i.checked = option;
        return i;
      });
      self.$set(self, "items", lst);
    },
    check(item) {
      let self = this;
      let lst = self.items || [];
      for (var i in lst) {
        if (lst[i].id == item.id) {
          lst[i].checked = !lst[i].checked;
          break;
        }
      }
      self.$set(self, "items", lst);
    },
    setItems() {
      let self = this;
      let ids = ((self.entry && self.entry.portal_permissions) || []).map(
        function(i) {
          return i.id;
        }
      );
      let lst = JSON.parse(
        JSON.stringify(self.$root.config.references.portal_permissions || [])
      ).map(function(i) {
        i.checked = ids.indexOf(i.id) >= 0;
        i.disabled = self.entry ? false : true;
        i.description = self.$utils
          .proper(self.$utils.removeDiacritics(i.description))
          .replace(/\-/g, " ");
        return i;
      });
      this.$set(this, "items", lst);
    },
    isBlocked(item) {
      return (this.group?.blocked_permissions || []).find(
        (permission) => permission.id == item.id
      )
        ? true
        : false;
    }
  }
};
</script>

<style scoped>
.items-content {
  /* height is gonna be 100% - document line height + 6px (margin and border from .checkbox-inline)
     or 450px, whichever is lower */
  max-height: min(calc(100% - (1.42857143em + 6px)), 450px);
  overflow-y: auto;
}
.checkbox-inline {
  margin-right: 10px;
}

.all {
  border-bottom: 1px solid grey;
  width: 100%;
}

span.disabled {
  color: grey;
  cursor: not-allowed;
}

.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}
.btn-gap {
  margin-right: 15px;
}

.permission:hover .permission-hint {
  display: inline-block;
}

.permission-hint {
  display: none;
}
</style>
