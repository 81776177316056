<template>
  <section>
    <div class="row">
      <div class="col-md-6">
        <UserGroupTable
          v-if="groups && !unselecting"
          v-bind:groups="groups"
          v-on:select="select"
          v-on:unselect="close"
          v-on:create="create"
          class="user-group-table"
        />
      </div>
      <div class="col-md-6" v-if="formGroup" data-testid="group-form">
        <UserGroupInlineForm v-bind:group="group" v-model="formGroup" />
        <UserGroupPermissions
          id="user-group-permissions"
          v-bind:group="group"
          v-model="formPermissions"
        />
        <hr />
        <div class="row">
          <div class="form-group col-sm-3">
            <button
              class="btn btn-default form-control"
              data-testid="cancel"
              v-bind:disabled="busy"
              v-on:click.prevent.stop="close"
            >
              {{ $t("cancel") }}
            </button>
          </div>
          <div class="form-group col-sm-3 col-sm-offset-3">
            <button
              class="btn form-control btn-default"
              v-if="
                group &&
                  group.id &&
                  !group.is_system &&
                  $can('manage', 'GrupoDeUsuariosCadastro')
              "
              data-testid="remove"
              v-bind:disabled="busy"
              v-on:click.prevent.stop="remove"
            >
              <i class="fa fa-trash"></i>
              {{ $t("delete") }}
            </button>
          </div>
          <div class="form-group col-sm-3">
            <button
              class="btn btn-primary"
              style="width: 100%"
              data-testid="save"
              v-bind:disabled="busy || !isValid"
              v-on:click.prevent.stop="save"
              v-if="$can('manage', 'GrupoDeUsuariosEscrita')"
            >
              <i class="fa fa-save"></i>
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MixinAlert from "@/project/mixin-alert.js";
import UserGroupInlineForm from "./user-group-inline-form.vue";
import UserGroupTable from "./user-group-table.vue";
import UserGroupPermissions from "./user-group-permissions.vue";
import UserGroup from "@/services/user-groups.js";

function getDefaultData() {
  return {
    busy: false,
    groups: null,
    group: null,
    unselecting: false,
    formPermissions: [], // model coupled to the form
    formGroup: null // model coupled to the form
  };
}

export default {
  name: "UserGroupDisplay",
  mixins: [MixinAlert],
  components: {
    UserGroupInlineForm,
    UserGroupTable,
    UserGroupPermissions
  },
  data() {
    return getDefaultData();
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  computed: {
    contract_id() {
      return this.$store.getters["user/loggedUser"].contract_id || 1;
    },
    payload() {
      let payload = {
        name: this.formGroup.name || "",
        description: this.formGroup.description || "",
        contract_id: this.contract_id,
        portal_permissions_ids: this.formPermissions
      };
      if (this.group) {
        payload.id = this.group.id;
        payload.etag = this.group.etag;
      }
      return payload;
    },
    isValid() {
      return (
        (!this.group || !this.group.is_system) &&
        this.formGroup &&
        this.formGroup.name &&
        this.formGroup.description &&
        this.formPermissions &&
        this.formPermissions.length
      );
    }
  },
  methods: {
    select(group) {
      let self = this;
      self.$set(self, "group", group);
      self.$set(self, "formGroup", null);
      self.$set(self, "formPermissions", null);
      self.$nextTick(function() {
        self.$set(self, "formGroup", {
          name: group.name,
          description: group.description
        });
      });
    },
    unselect() {
      let self = this;
      self.$set(self, "formPermissions", []);
      self.$set(self, "formGroup", null);
      self.group = null;
      self.unselecting = true;
      self.$nextTick(function() {
        self.unselecting = false;
      });
    },
    fetch() {
      let self = this;
      let srv = new UserGroup();
      let query = {
        contract_id: self.contract_id
      };
      this.busy = true;
      srv.fetch(query).then((response) => {
        this.busy = false;
        if (response) {
          self.$set(self, "groups", response);
          self.unselect();
        }
      });
    },
    create() {
      let self = this;
      self.$set(self, "group", null);
      self.$set(self, "formPermissions", []);
      self.$set(self, "formGroup", null);
      self.$nextTick(function() {
        self.$set(self, "formGroup", {
          name: "",
          description: ""
        });
      });
    },
    save() {
      let self = this;
      let srv = new UserGroup();
      let payload = self.payload;
      self.busy = true;
      srv.save(payload).then((response) => {
        self.busy = false;
        if (typeof response == "string") {
          self.alert = {
            title: self.$t("item_could_not_be_saved"),
            text: response,
            type: "error"
          };
          self.showAlert();
        } else {
          self.$store.dispatch("role/fetchRoles");
          self.fetch();
          self.alert = {
            title: self.$t("saved"),
            text: self.$t("you_have_saved_n_items", { count: 1 }),
            type: "success"
          };
          self.showAlert(self.close);
        }
      });
    },
    remove() {
      let self = this;
      self
        .validatePrivileges("manage", "MembroDeContratoEscrita")
        .then((isOk) => {
          if (isOk) {
            self
              .$swal({
                title: self.$t("are_you_sure"),
                text: self.$t("you_wont_be_able_to_revert_this"),
                icon: "warning",
                buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
              })
              .then(function(isConfirm) {
                if (isConfirm) {
                  self.doRemove(self.payload, new UserGroup()); // child must implement it
                }
              });
          }
        });
    },
    doRemove: function(payload, srv) {
      let self = this;
      self.busy = true;
      srv.remove(payload).then((ret) => {
        self.busy = false;
        if (self.validateDeleteResponse(ret)) {
          self.exitSuccess = true;
          self.fetch();
          self.showAlert(self.close);
        } else {
          self.showAlert();
        }
      });
    },
    close() {
      this.unselect();
    }
  },
  created() {
    this.fetch();
  }
};
</script>

<style scoped>
section > .row,
section > .row > [class|="col"] {
  height: inherit;
}

section .user-group-table {
  height: 98%;
}

#user-group-permissions {
  height: 65%;
}
</style>
