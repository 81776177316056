<template>
  <form>
    <div>
      <label for="">
        {{
          group ? entry.name : `${$tc("new")} ${$t("user_group").toLowerCase()}`
        }}
        <Tooltip v-if="!group" :title="$t('hints.new_group')" />
      </label>
    </div>
    <div class="row">
      <div class="col-sm-5">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            ref="group_name"
            data-testid="name"
            data-toggle="popover"
            :data-content="
              group
                ? group.is_system
                  ? $t('titles.group_is_system')
                  : $t('titles.group_name')
                : ''
            "
            v-model="entry.name"
            v-bind:placeholder="$t('name')"
            v-bind:readonly="
              (group && group.is_system) ||
                !$can('manage', 'GrupoDeUsuariosEscrita')
            "
            required
          />
        </div>
      </div>
      <div class="col-sm-7">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="entry.description"
            data-testid="description"
            data-toggle="popover"
            :data-content="
              group
                ? group.is_system
                  ? $t('titles.group_is_system')
                  : $t('titles.group_description')
                : ''
            "
            v-bind:placeholder="$t('description')"
            v-bind:readonly="
              (group && group.is_system) ||
                !$can('manage', 'GrupoDeUsuariosEscrita')
            "
            required
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";

function getDefaultEntry() {
  return {
    id: "",
    name: "",
    description: ""
  };
}
export default {
  name: "UserGroupInlineForm",
  components: { Tooltip },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    group: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      entry: getDefaultEntry(),
      busy: false
    };
  },
  watch: {
    entry: {
      handler(n) {
        this.$emit("input", n);
      },
      deep: true
    }
  },
  computed: {
    payload() {
      return {};
    },
    isValid() {
      return this.entry && this.entry.name && this.entry.description
        ? true
        : false;
    }
  },
  methods: {
    initData() {
      if (this.value) {
        this.$set(this, "entry", JSON.parse(JSON.stringify(this.value)));
      } else {
        this.$set(this, "entry", getDefaultEntry());
      }
      this.focus();
    },
    focus() {
      this.$nextTick(function() {
        this.$refs.group_name.focus();
      });
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    $(this.$el)
      .find("[data-toggle=popover]")
      .popover({ trigger: "hover", delay: { show: 500 }, placement: "auto" });
  }
};
</script>

<style scoped>
.btn-gap {
  margin-right: 5px;
}
</style>
